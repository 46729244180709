import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import {
    Box,
    Drawer,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { MHidden } from '../components/@material-extend';
import Logo from '../components/Logo';
import NavSection from '../components/NavSection';
import Scrollbar from '../components/Scrollbar';
import { SETTINGS } from 'src/config/settings';
import userService from 'src/services/UserServiceClass';
import { sidebarConfig } from '../config/SidebarConfig';
import CenterLoading from 'src/components/misc/CenterLoading';
import GemImage from 'src/components/GemImage';
import Dialog from 'src/components/misc/alerts/Dialog';
import generalService from 'src/services/GeneralServiceClass';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    minHeight: '60rem',
    borderRight: '1px solid rgba(145, 158, 171, 0.24)',
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();
    const loggedInUser = userService.getLoggedInUser();
    const selectedGame = localStorage.getItem('selectedGame') ?? 1;
    const selectedServer = localStorage.getItem('selectedServer');
    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDia, setOpenDia] = useState(false);
    const [potentialGameSelection, setPotentialGameSelection] = useState(null);
    const [serverData, setServerData] = useState({});

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const getServersData = () => {
        generalService.getServers()
            .then((data) => {
                setServerData(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(getServersData, []);


    const getData = () => {
        setLoading(true);
        userService
            .getMyPoints(selectedGame, selectedServer)
            .then((data) => {
                setPoints(data.points);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(getData, []);

    const handleGameChange = (event) => {
        const { value } = event.target;

        if (value == selectedGame) {
            return;
        }

        setPotentialGameSelection(value);
        setOpenDia(true);
    };

    const handleConfirm = () => {
        localStorage.setItem('selectedGame', potentialGameSelection);
        localStorage.removeItem('selectedServer');
        window.location.reload();
    };

    const changeServerChange = (event) => {
        const { value } = event.target;

        if (value == selectedServer) {
            return;
        }

        localStorage.setItem('selectedServer', value);
        window.location.reload();
    }

    const handleClose = () => {
        setOpenDia(false);
        setPotentialGameSelection(null);
    };

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <MHidden width="lgUp">
                {' '}
                <Box sx={{ px: 2.5, py: 6, pb: 10 }}>
                    <Logo />
                </Box>
                {!SETTINGS.GAMES[selectedGame].local_currency && (
                    <Box>
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item>
                                <GemImage />
                            </Grid>

                            <Grid item>
                                {loading ? (
                                    <CenterLoading />
                                ) : (
                                    <Typography
                                        sx={{ textAlign: 'center' }}
                                        variant="h3"
                                        noWrap
                                    >
                                        {points}
                                    </Typography>

                                )}
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Box sx={{ pb: 0, pl: 5, pr: 2.5, pt: 4 }}>
                    <FormControl fullWidth>
                        <InputLabel id="game-label">Select Game</InputLabel>
                        <Select
                            labelId="game-select-label"
                            id="game-simple-select"
                            value={selectedGame}
                            onChange={handleGameChange}
                            label="Select Game"
                        >
                            {SETTINGS.GAMES.map((a) => {
                                return (
                                    <MenuItem key={a.id} value={a.id}>
                                        {a.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                {/* {
                    (SETTINGS.GAMES[selectedGame]?.local_currency && serverData[selectedGame]) && (
                        <Box sx={{ pb: 6, pl: 5, pr: 2.5, pt: 4 }}>
                            <FormControl fullWidth>
                                <InputLabel id="server-label">Select Server</InputLabel>
                                <Select
                                    labelId="server-select-label"
                                    id="server-simple-select"
                                    value={selectedServer}
                                    onChange={changeServerChange}
                                    label="Select Server"
                                >
                                    {serverData[selectedGame]?.map((a) => {
                                        return (
                                            <MenuItem key={`${a.id}-${selectedGame}`} value={a.id}>
                                                {a.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    )} */}


                <Dialog
                    buttonText={'Close'}
                    openDialog={!!openDia}
                    handleButton={handleClose}
                    handleButton2={handleConfirm}
                    buttonText2={'Confirm'}
                    style={{ color: 'white' }}
                    noIcon
                >

                    {`Are you sure you want to change the Game Selection to `}
                    <span
                        style={{ fontWeight: 'bold' }}
                    >{`${SETTINGS.GAMES[potentialGameSelection]?.name}`}</span>?
                    <img src={SETTINGS.GAMES[potentialGameSelection]?.image} alt={SETTINGS.GAMES[potentialGameSelection]?.name} />

                </Dialog>

            </MHidden>

            <MHidden width="lgUp">
                {' '}
                <NavSection navConfig={sidebarConfig.mobile} />
            </MHidden>

            <MHidden width="lgDown">
                {' '}

                {selectedGame == 1 ? (
                    <Box sx={{ px: 2.5 }}>
                        <img src={SETTINGS.GAMES[selectedGame]?.image} alt={SETTINGS.GAMES[selectedGame]?.name} />
                    </Box>) :
                    (<Box sx={{ px: 2.5, py: 4 }}>
                        <img src={SETTINGS.GAMES[selectedGame]?.image} alt={SETTINGS.GAMES[selectedGame]?.name} />
                    </Box>)
                }

                {!SETTINGS.GAMES[selectedGame].local_currency && (
                    <Box >
                        <Grid container spacing={1} justifyContent="center" alignItems="center">
                            <Grid item>
                                <GemImage />
                            </Grid>

                            <Grid item>
                                {loading ? (
                                    <CenterLoading />
                                ) : (
                                    <>

                                        <Typography
                                            sx={{ textAlign: 'center' }}
                                            variant="h3"
                                            noWrap
                                        >
                                            {points}
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Box sx={{ pb: 0, pl: 5, pr: 2.5, pt: 4 }}>
                    <FormControl fullWidth>
                        <InputLabel id="game-label">Select Game</InputLabel>
                        <Select
                            labelId="game-select-label"
                            id="game-simple-select"
                            value={selectedGame}
                            onChange={handleGameChange}
                            label="Select Game"
                        >
                            {SETTINGS.GAMES.map((a) => {
                                return (
                                    <MenuItem key={a.id} value={a.id}>
                                        {a.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                {/* {
                    (SETTINGS.GAMES[selectedGame]?.local_currency && serverData[selectedGame]) && (
                        <Box sx={{ pb: 6, pl: 5, pr: 2.5, pt: 4 }}>
                            <FormControl fullWidth>
                                <InputLabel id="server-label">Select Server</InputLabel>
                                <Select
                                    labelId="server-select-label"
                                    id="server-simple-select"
                                    value={selectedServer}
                                    onChange={changeServerChange}
                                    label="Select Server"
                                >
                                    {serverData[selectedGame]?.map((a) => {
                                        return (
                                            <MenuItem key={`${a.id}-${selectedGame}`} value={a.id}>
                                                {a.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    )} */}
                <Dialog
                    buttonText={'Close'}
                    openDialog={!!openDia}
                    handleButton={handleClose}
                    handleButton2={handleConfirm}
                    buttonText2={'Confirm'}
                    style={{ color: 'white' }}
                    noIcon
                >

                    {`Are you sure you want to change the Game Selection to `}
                    <span
                        style={{ fontWeight: 'bold' }}
                    >{`${SETTINGS.GAMES[potentialGameSelection]?.name}`}</span>?
                    <img src={SETTINGS.GAMES[potentialGameSelection]?.image} alt={SETTINGS.GAMES[potentialGameSelection]?.name} />

                </Dialog>
            </MHidden>

            <NavSection
                navConfig={sidebarConfig.top.filter(
                    (value) => !value.rank || loggedInUser?.rank >= value.rank,
                )}
            />

            <Box sx={{ flexGrow: 1 }} />

            <MHidden width="lgUp">
                {' '}
                <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                    <NavSection navConfig={sidebarConfig.bottom} />
                </Box>
            </MHidden>
        </Scrollbar >
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {
                            height: '100%',
                            marginTop: 0,
                            width: DRAWER_WIDTH,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            height: '100%',
                            // borderRight: 'inherit',
                            marginTop: 11.5,
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            position: 'relative',
                            zIndex: 0,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
