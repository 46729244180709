// material
import * as React from 'react';
import { FormControl, InputLabel, Select, Grid, MenuItem, TextField } from '@mui/material';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import utcPlugin from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import { SETTINGS } from 'src/config/settings';
import { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import Countdown from './Countdown';
import WeeklyCountdown from './WeeklyCountdown';
import MonthlyCountdown from './MonthlyCountdown';
import YearlyCountdown from './YearlyCountdown';
import enLocale from 'date-fns/locale/en-GB';

// ----------------------------------------------------------------------

dayjs.extend(utcPlugin);
dayjs.extend(isBetweenPlugin);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1,
});

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

export default function Selector({ selectedDate, setSelectedDate, selector, setSelector }) {
    // function getWeekOfMonth(date) {
    //     const startOfMonth = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
    //     const firstDayOfMonth = startOfMonth.getDay();
    //     const dayOfMonth = date.getUTCDate();

    //     // Calculate the week number
    //     const weekNumber = Math.ceil((firstDayOfMonth + dayOfMonth) / 7);

    //     return weekNumber;
    // }

    function getWeekOfMonth(date) {
        const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const firstDayOfMonth = startOfMonth.getDay();
        const dayOfMonth = date.getDate();

        // Calculate the week number
        const weekNumber = Math.ceil((firstDayOfMonth + dayOfMonth) / 7);

        return weekNumber;
    }

    // const [selector, setSelector] = useState(SETTINGS.SELECTOR[0]);
    // const [selectedDate, setSelectedDate] = useState(new Date());
    const minDate = new Date('2023-06-05');
    const maxDate = new Date();

    const handleSelectorChange = (event) => {
        const { value } = event.target;
        setSelector(value);
        setSelectedDate(dayjs.utc().toDate());

        //window.location.reload();
    };

    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
    };

    const [value, setValue] = React.useState(dayjs.utc());

    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
        if (!value) {
            return <PickersDay {...pickersDayProps} />;
        }

        const start = value.startOf('week').utc();
        const end = value.endOf('week').utc();

        // const start = value.startOf('week').startOf('day').add(1, 'day').utc();
        // const end = value.endOf('week').startOf('day').add(1, 'day').utc();

        const dayIsBetween = date.isBetween(start, end, null, '[]');
        const isFirstDay = date.isSame(start, 'day');
        const isLastDay = date.isSame(end, 'day');

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel id="date-selector-label">Ranking Type</InputLabel>
                                <Select
                                    labelId="date-selector-select-label"
                                    id="date-selector-simple-select"
                                    value={selector}
                                    onChange={handleSelectorChange}
                                    label="Ranking Type"
                                >
                                    {SETTINGS.SELECTOR.map((a) => {
                                        return (
                                            <MenuItem key={a} value={a}>
                                                {a}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                            {' '}
                            {selector === 'Daily' && (
                                <>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={enLocale}
                                    >
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            label="Date"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                        />
                                    </LocalizationProvider>
                                </>
                            )}
                            {selector === 'Weekly' && (
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {/* <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                label="Week picker"
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderDay={renderWeekPickerDay}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="'Week of' MMM d"
                            /> */}
                                        <DatePicker
                                            label="Week"
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                                console.log('Date: ', new Date(newValue.format()));
                                                //setSelectedDate(new Date(newValue.format()));
                                                setSelectedDate(dayjs(newValue).utc().toDate());
                                            }}
                                            renderDay={renderWeekPickerDay}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                            //inputFormat={`Week ${getWeekOfMonth(new Date(value))} of MMM`}
                                            inputFormat={`Week ${getWeekOfMonth(
                                                dayjs(value).toDate(),
                                            )} of MMM`}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                        />
                                    </LocalizationProvider>
                                </>
                            )}
                            {selector === 'Monthly' && (
                                <>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={enLocale}
                                    >
                                        <DatePicker
                                            views={['month', 'year']}
                                            label="Month"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                        />
                                    </LocalizationProvider>

                                    {/* <Countdown interval="monthly" /> */}
                                </>
                            )}
                            {selector === 'Yearly' && (
                                <>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={enLocale}
                                    >
                                        <DatePicker
                                            views={['year']}
                                            label="Year"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                            minDate={minDate}
                                        />
                                    </LocalizationProvider>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
