/*
  Imports
*/
import { Container, Grid } from '@mui/material';
import { useState, Children } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';
import GemStoreCard from 'src/components/misc/cards/GemStoreCard';
import Dialog from 'src/components/misc/alerts/Dialog';
import checkoutService from 'src/services/CheckoutServiceClass';
import ServerError from 'src/components/misc/alerts/ServerError';
import Xsolla from 'src/components/Xsolla';
/*
  Main Working
*/
export default () => {
    /*
  States, Params, Navigation, Query, Variables.
  */

    //Get gameid from query url
    // const { gameid } = useParams();
    const gameid = localStorage.getItem('selectedGame') ?? 1;
    const serverid = localStorage.getItem('selectedServer') ?? 'world1';
    const [openDia, setOpenDia] = useState(false);
    const [error, setServerError] = useState(false);

    const title = `Fawkes' Gems - ${SETTINGS.GAMES[gameid].name}`;

    const [buyingItem, setBuyingItem] = useState(false);

    const handleClick = (item) => {
        setOpenDia(item);
    };

    const handleConfirm = () => {
        const item = openDia;
        const game = gameid;
        const quantity = 1;

        setBuyingItem(true);
        checkoutService
            .checkout({ item: item.sku[gameid], quantity: quantity, game: game })
            .then(async (res) => {
                // try {
                //     if (+gameid > 1)
                //         await checkoutService.createOrder(game, serverid, res.id);
                window.location.href = res.url;
                // } catch (error) {
                //     console.error('Error Reset Button', error, error.response);
                //     if (!error.response) {
                //         setServerError('Error occurred please try later');
                //     } else {
                //         setServerError(error.response.data.msg);
                //     }
                // }

            })
            .catch((err) => {
                console.error('Error Reset Button', err, err.response);
                if (!err.response) {
                    setServerError('Error occurred please try later');
                } else {
                    setServerError(err.response.data.msg);
                }
            })
            .finally(() => setBuyingItem(false));
    };

    const handleClose = () => {
        setOpenDia(false);
    };

    return (
        <Page title={title}>
            <Container>
                <ListPageTitle mb={5}>{title}</ListPageTitle>
                <ServerError open={!!error}>{error}</ServerError>
                <Grid container justifyContent={'center'}>
                    {Children.toArray(
                        SETTINGS.STORE?.map((item) => (
                            <Grid sx={{ margin: 1, width: { xs: '90%', sm: '40%', md: '30%' } }}>
                                <GemStoreCard
                                    item={item}
                                    handleClick={handleClick}
                                    loading={buyingItem}
                                // disabled={!+gameid}
                                />
                            </Grid>
                        )),
                    )}
                    <Dialog
                        buttonText={'Close'}
                        openDialog={!!openDia}
                        handleButton={handleClose}
                        handleButton2={handleConfirm}
                        buttonText2={'Confirm'}
                        style={{ color: 'white' }}
                        disabledButton1={buyingItem}
                        disabledButton2={buyingItem}
                        noIcon
                    >
                        {`You are buying `}
                        <span style={{ fontWeight: 'bold' }}>{`${openDia.quantity}`}</span>
                        {` Fawkes' Gems for `}
                        <span style={{ fontWeight: 'bold' }}>{`$${openDia.price}`}</span>
                        {` in the game `}
                        <span
                            style={{ fontWeight: 'bold' }}
                        >{`${SETTINGS.GAMES[gameid].name}.`}</span>
                    </Dialog>
                </Grid>
                <Xsolla />
            </Container>
        </Page>
    );
};
