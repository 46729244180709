let BASIC_SETTINGS = {
    TITLE: 'Fawkes Games',
    fullTitle: 'Fawkes Games',
    tabTitle: 'Fawkes’ Account',
    rowsPerPageList: [10, 30, 50, 100, 500],
    defaultPerPage: 10,
    // BackendURL: 'http://127.0.0.1:3001/', //Local
    BackendURL: 'https://api.fawkesgames.com/', //Production
    // BackendURL: 'https://api.test.fawkesgames.com/', //Dev
    FreshDeskUrl: 'https://fawkes.myfreshworks.com/sp/OIDC/541940099085962415/implicit',
    SANDBOX: false,

    LogoImage: '/static/logos/fawkesgames/logo.png',
    FawkesImage: '/static/logos/fawkesgames/fawkes.png',
    GemImage: '/static/illustrations/gem.png',
    FaviconImage: '/static/logos/fawkesgames/favicon.png',
    UserLoginImage: '/static/illustrations/user.png',
    ForgetPageImage: '/static/illustrations/forgot.png',
    LinkdinLogo: '/static/logos/fawkesgames/linkedin.png',
    DiscordLogo: '/static/logos/fawkesgames/discord.png',
    FacebookLogo: '/static/logos/fawkesgames/facebook.png',

    Image404: '/static/illustrations/404.png',
    Image401: '/static/illustrations/401.png',
    ImageCommingSoon: '/static/illustrations/coming-soon.png',
    HammerImage: '/static/illustrations/hammer.png',
    HammerBackgroundImage: '/static/illustrations/HammerBackgroundImage.png',
    Xsolla: '/static/illustrations/xsolla.png',
    DefaultAvatar: '/static/illustrations/avatar.webp',

    DefaultUploadedFileImage: '/static/icons/file.png',

    acceptImageUpload: 'image/png, image/jpg, image/jpeg',
    acceptFileUpload:
        'image/png, image/jpg, image/jpeg, audio/mp3, application/pdf, application/zip, application/doc, application/docx, application/ppt, application/pptx, application/txt',
    maxUploadFileSize: 10 * 1024 * 1024, //10 Mbs

    GAMES: [
        {
            name: 'Last Chaos',
            id: 0,
            currency_sku: 'fawkes-gems-lastchaos',
            image: '/static/images/games/lastchaos.png',
            projectId: '207056',
            web_route: 'https://fawkesgames.com/last-chaos',
            servers: [
                { label: 'Horizon', value: '4' },
                { label: 'Osiris', value: '1' },
                { label: 'Pandora', value: '2' },
            ],
        },
        {
            name: 'Shaiya',
            id: 1,
            currency_sku: 'fawkes-gems-1',
            image: '/static/images/games/shaiya.png',
            projectId: '206987',

            web_route: 'https://fawkesgames.com/shaiya',
            servers: [
                // { label: 'Staging', value: '1' }, //Staging
                { label: 'EU', value: '2' },
                { label: 'US', value: '3' },
                { label: 'ES', value: '4' },
                { label: 'PT', value: '5' },
                { label: 'USINT', value: '6' },
                { label: 'EUINT', value: '7' },
            ],
        },
        {
            name: 'Desert Operations',
            id: 2,
            currency_sku: 'fawkes-gems-2',
            image: '/static/images/games/desertoperations.png',
            projectId: '263493',
            local_currency: true,
            web_route: 'https://desertoperations.fawkesgames.com/',
            servers: [
                { label: 'Legacy', value: 'world1' },
                { label: 'Citadel', value: 'world2' },
                { label: 'Mercury', value: 'world3' },
                { label: 'Armageddon', value: 'world4' },
                { label: 'Arena ', value: 'world5' },
                { label: 'Blood of iron', value: 'world6' },
                { label: 'Valkyrie', value: 'world7' },
                { label: 'Chaos', value: 'world8' },
                { label: 'CrossFire', value: 'world9' },
                { label: 'BK Storm', value: 'bk1' },
            ],
        },
        {
            name: 'Wargame 1942',
            id: 3,
            currency_sku: 'fawkes-gems-3',
            image: '/static/images/games/wargame1942.png',
            projectId: '263494',
            local_currency: true,
            web_route: 'https://wargame1942.fawkesgames.com/',
            servers: [
                { label: 'Midway', value: 'world1' },
                { label: 'Skylight', value: 'world2' },
                { label: 'Firestorm', value: 'world3' },
                { label: 'BK Fury', value: 'bk1' },
            ],
        },
    ],

    FILTERS: [
        {
            Server: 'Light',
            id: '0',
            image: '/static/images/faction/light.png',
            Class: [
                { label: 'Fighter', image: '/static/images/class/fighter-Warrior.png' },
                { label: 'Defender', image: '/static/images/class/defender-guardian.png' },
                { label: 'Ranger', image: '/static/images/class/ranger-assassin.png' },
                { label: 'Archer', image: '/static/images/class/archer-hunter.png' },
                { label: 'Mage', image: '/static/images/class/mage-pagan.png' },
                { label: 'Priest', image: '/static/images/class/priest-oracle.png' },
            ],
        },
        {
            Server: 'Fury',
            id: '1',
            image: '/static/images/faction/fury.png',
            Class: [
                { label: 'Warrior', image: '/static/images/class/fighter-Warrior.png' },
                { label: 'Guardian', image: '/static/images/class/defender-guardian.png' },
                { label: 'Assassin', image: '/static/images/class/ranger-assassin.png' },
                { label: 'Hunter', image: '/static/images/class/archer-hunter.png' },
                { label: 'Pagan', image: '/static/images/class/mage-pagan.png' },
                { label: 'Oracle', image: '/static/images/class/priest-oracle.png' },
            ],
        },
    ],

    STORE: [
        {
            price: 4.99,
            id: 0,
            quantity: 115,
            sku: ['115-gems-lc', '115-gems-sy', '', 'test-gems-1-1'],
            image: '/static/images/store/m1.png',
        },
        {
            price: 10.99,
            id: 1,
            quantity: 270,
            sku: ['270-gems-lc', '270-gems-sy'],
            image: '/static/images/store/m2.png',
        },
        {
            price: 21.99,
            id: 2,
            quantity: 550,
            sku: ['550-gems-lc', '550-gems-sy'],
            image: '/static/images/store/m3.png',
        },
        {
            price: 34.99,
            id: 3,
            quantity: 885,
            sku: ['885-gems-lc', '885-gems-sy'],
            image: '/static/images/store/m4.png',
        },
        {
            price: 49.99,
            id: 4,
            quantity: 1275,
            sku: ['1275-gems-lc', '1275-gems-sy'],
            image: '/static/images/store/m5.png',
        },
        {
            price: 99.99,
            id: 5,
            quantity: 2750,
            sku: ['2750-gems-lc', '2750-gems-sy'],
            image: '/static/images/store/m6.png',
        },
    ],

    COMPANY: ['Gamigo'],

    SELECTOR: ['Daily', 'Weekly', 'Monthly', 'Yearly'],

    //Chest Images

    CHEST_IMAGES: {
        Daily: {
            1: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            2: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            3: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            4: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            5: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            6: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            7: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            8: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            9: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            10: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
        },
        Weekly: {
            1: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            2: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            3: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            4: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            5: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            6: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            7: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            8: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            9: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            10: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            11: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            12: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            13: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            14: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            15: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
        },
        Monthly: {
            1: {
                chest: 'Mythical Chest',
                image: '/static/images/chest/mythicalchest.png',
                backgroundColor: '#f4cccc',
                description: 'Holds mythical and legendary items of immense power.',
            },
            2: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            3: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            4: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            5: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            6: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            7: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            8: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            9: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            10: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            11: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            12: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            13: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            14: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            15: {
                chest: 'Uncommon Chest',
                image: '/static/images/chest/uncommonchest.png',
                backgroundColor: '#d9ead3',
                description: 'Holds slightly rarer items than the basic chest.',
            },
            16: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            17: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            18: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            19: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
            20: {
                chest: 'Basic Chest',
                image: '/static/images/chest/basicchest.png',
                backgroundColor: '#d9d9d9',
                description: 'Contains common and basic items.',
            },
        },
        Yearly: {
            1: {
                chest: 'Celestial Chest',
                image: '/static/images/chest/celestialchest.png',
                backgroundColor: '#00ffff',
                description: 'Holds treasures of celestial significance and beauty.',
            },
            2: {
                chest: 'Divine Chest',
                image: '/static/images/chest/divinechest.png',
                backgroundColor: '#a2c4c9',
                description: 'Contains items of divine origin and immense value.',
            },
            3: {
                chest: 'Ascendant Chest',
                image: '/static/images/chest/ascendantchest.png',
                backgroundColor: '#d0e0e3',
                description: 'Contains items of godlike rarity and power.',
            },
            4: {
                chest: 'Mythical Chest',
                image: '/static/images/chest/mythicalchest.png',
                backgroundColor: '#f4cccc',
                description: 'Holds mythical and legendary items of immense power.',
            },
            5: {
                chest: 'Mythical Chest',
                image: '/static/images/chest/mythicalchest.png',
                backgroundColor: '#f4cccc',
                description: 'Holds mythical and legendary items of immense power.',
            },
            6: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            7: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            8: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            9: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            10: {
                chest: 'Legendary Chest',
                image: '/static/images/chest/legendarychest.png',
                backgroundColor: '#fff2cc',
                description: 'Contains incredibly rare and highly sought-after items.',
            },
            11: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            12: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            13: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            14: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            15: {
                chest: 'Epic Chest',
                image: '/static/images/chest/epicchest.png',
                backgroundColor: '#ead1dc',
                description: 'Holds powerful and rare items.',
            },
            16: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            17: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            18: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            19: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            20: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            21: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            22: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            23: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            24: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
            25: {
                chest: 'Rare Chest',
                image: '/static/images/chest/rarechest.png',
                backgroundColor: '#c9daf8',
                description: 'Contains valuable and harder-to-find items.',
            },
        },
    },

    //PALETTE

    PRIMARY: {
        lighter: '#ee6f63',
        light: '#ec5a4c',
        lightest: '#f1857a',
        main: '#e94535',
        dark: '#e6301e',
        darker: '#d42817',
        contrastText: '#fff',
    },

    SECONDARY: {
        lighter: '#e3f1d1',
        light: '#badd8b',
        main: '#99CC54',
        dark: '#7db135',
        darker: '#648e2a',
        contrastText: '#fff',
    },

    RECAPTCHA: {
        SITE_KEY: '6LfPXdkkAAAAAKZ17UBLPyHyz4aqVbwry-VMJyD4',
    },
};

BASIC_SETTINGS = {
    ...BASIC_SETTINGS,
    // ...SETTINGS_OVERRIDE, //Comment this in local testing
};

BASIC_SETTINGS.BackendURLAPI = BASIC_SETTINGS.BackendURL + 'api/';
BASIC_SETTINGS.ResetPageImage = BASIC_SETTINGS.ForgetPageImage;
BASIC_SETTINGS.DataReload = BASIC_SETTINGS.NotificationReload;

export const SETTINGS = BASIC_SETTINGS;
