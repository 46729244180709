import * as React from 'react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { AppBar, Box, Button, Grid, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink, Link as RouterLink, useNavigate } from 'react-router-dom';
import HeaderLogo from 'src/components/HeaderLogo';
import { MHidden } from '../components/@material-extend';
import userService from '../services/UserServiceClass';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {
    RouteDiscord,
    RouteHome,
    RouteLastChaos,
    RouteLogin,
    RouteNews,
    RouteRegister,
    RouteShaiya,
    RouteStore,
} from 'src/config/routes';
import { SETTINGS } from 'src/config/settings';

const DRAWER_WIDTH = '100%';
const APPBAR_MOBILE = 64.6;
const APPBAR_DESKTOP = 64.6;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    // backgroundColor: 'red',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
    borderBottom: 1,
    borderColor: theme.palette.error,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: 'text.primary',
    fontFamily: 'Alegreya Sans SC',
    fontSize: '1rem',
    '&:hover': {
        backgroundColor: theme.palette.primary.main, // Change the background color on hover
        color: 'text.primary',
    },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    '&.active': {
        color: theme.palette.primary.main,
    },
}));

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
    isAdmin: PropTypes.bool,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    const navigator = useNavigate();
    const [user, setUser] = useState({ username: null });
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMouseEnter = () => {
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleLogout = () => {
        navigator('/logout', { replace: true });
    };

    useEffect(() => {
        setUser(userService.getLoggedInUser());
        // console.log('user', user);
        //Rank based display here
    }, []);

    const linkStyle = {
        color: 'text.primary',
        fontFamily: 'Alegreya Sans SC',
        fontSize: '0.9rem',

        '&:hover': {
            color: 'primary.main',
            cursor: 'pointer',
        },
    };

    const typographyStyle = {
        fontFamily: 'Alegreya Sans SC',
        fontSize: '1.1rem',
        letterSpacing: 'normal',
        fontWeight: 'normal',
    };
    return (
        <RootStyle>
            <ToolbarStyle
                sx={{
                    borderBottom: 0.5,
                    borderColor: '#232629',
                    backgroundColor: 'black',
                }}
            >
                {user?.username ? (
                    <>
                        <MHidden width="lgUp">
                            <IconButton
                                onClick={onOpenSidebar}
                                sx={{ mr: 1, color: 'text.primary' }}
                            >
                                <Icon icon={menu2Fill} />
                            </IconButton>
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack direction="row" alignItems="center" spacing={{ lg: 2, md: 2 }}>
                                <Typography variant="subtitle1">{user?.username}</Typography>
                            </Stack>
                        </MHidden>
                        <MHidden width="lgDown">
                            <Box sx={{ flexGrow: 0.8, pl: '9.5rem' }}>
                                <Grid
                                    container
                                    direction="row"
                                    spacing={4.2}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Grid item>
                                        <HeaderLogo />
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            href={RouteHome}
                                            underline="none"
                                            sx={linkStyle}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Typography sx={typographyStyle}>Home</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            href={RouteNews}
                                            underline="none"
                                            sx={linkStyle}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Typography sx={typographyStyle}>News</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            ref={anchorRef}
                                            id="composition-button"
                                            aria-controls={open ? 'composition-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                            // onClick={handleToggle}
                                            underline="none"
                                            sx={linkStyle}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <Typography sx={typographyStyle}>Games</Typography>
                                        </Link>
                                        <Popper
                                            open={open}
                                            anchorEl={anchorRef.current}
                                            role={undefined}
                                            placement="bottom-start"
                                            transition
                                            disablePortal
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'bottom-start'
                                                                ? 'left top'
                                                                : 'left bottom',
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener
                                                            onClickAway={handleClose}
                                                        >
                                                            <MenuList
                                                                id="composition-menu"
                                                                aria-labelledby="composition-button"
                                                                onKeyDown={handleListKeyDown}
                                                                variant="menu"
                                                            >
                                                                {SETTINGS.GAMES.map(
                                                                    (game, index) => (
                                                                        <StyledMenuItem
                                                                            key={index}
                                                                            onClick={handleClose}
                                                                            component={Link}
                                                                            target="_blank"
                                                                            href={game.web_route}
                                                                        >
                                                                            {game.name}
                                                                        </StyledMenuItem>
                                                                    ),
                                                                )}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Grid>
                                    <Grid item>
                                        <Link
                                            href={RouteDiscord}
                                            target="_blank"
                                            underline="none"
                                            sx={linkStyle}
                                        >
                                            <Typography sx={typographyStyle}>Discord</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <StyledNavLink
                                            component={RouterLink}
                                            to={RouteStore}
                                            underline="none"
                                            sx={linkStyle}
                                        >
                                            <Typography sx={typographyStyle}>Store</Typography>
                                        </StyledNavLink>
                                    </Grid>
                                </Grid>
                            </Box>
                        </MHidden>
                    </>
                ) : (
                    <>
                        <MHidden width="lgUp">
                            <IconButton
                                onClick={onOpenSidebar}
                                sx={{ mr: 1, color: 'text.primary' }}
                            >
                                <Icon icon={menu2Fill} />
                            </IconButton>
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack
                                direction="row"
                                alignItems="left"
                                spacing={{ xs: 2, lg: 2, md: 2 }}
                            >
                                <Button
                                    fullWidth
                                    color="inherit"
                                    variant="outlined"
                                    onClick={() => navigator(RouteLogin, { replace: false })}
                                    style={{
                                        backgroundColor: '#E94535',
                                        height: 25,
                                        alignSelf: 'center',
                                        width: 20,
                                    }}
                                >
                                    Login
                                </Button>
                                <Button
                                    fullWidth
                                    color="inherit"
                                    variant="outlined"
                                    onClick={() => navigator(RouteRegister, { replace: false })}
                                    style={{
                                        // backgroundColor: '#E94535',
                                        maxWidth: 25,
                                        height: 25,
                                        alignSelf: 'center',
                                        width: 20,
                                    }}
                                >
                                    Register
                                </Button>
                            </Stack>
                        </MHidden>
                        <MHidden width="lgDown">
                            <Box sx={{ flexGrow: 0.8, pl: '9rem' }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item md={1}>
                                        <HeaderLogo />
                                    </Grid>
                                    <Grid item md={1}>
                                        <Link href={RouteHome} underline="none" sx={linkStyle}>
                                            <Typography>HOME</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item md={1}>
                                        <Link
                                            href={RouteDiscord}
                                            target="_blank"
                                            underline="none"
                                            sx={linkStyle}
                                        >
                                            <Typography>DISCORD</Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </MHidden>
                    </>
                )}
                <MHidden width="lgDown">
                    {user?.username ? (
                        <>
                            <Stack direction="row" alignItems="center" spacing={{ lg: 2, md: 2 }}>
                                <Typography variant="subtitle1">{user.username}</Typography>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleLogout}
                                    style={{
                                        borderRadius: 6,
                                        fontSize: '0.9rem',
                                    }}
                                >
                                    Logout
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack direction="row" alignItems="left" spacing={{ lg: 2, md: 2 }}>
                                <Button
                                    fullWidth
                                    color="inherit"
                                    variant="outlined"
                                    onClick={() => navigator(RouteLogin, { replace: false })}
                                    style={{ backgroundColor: '#E94535' }}
                                >
                                    Login
                                </Button>
                                <Button
                                    fullWidth
                                    color="inherit"
                                    variant="outlined"
                                    onClick={() => navigator(RouteRegister, { replace: false })}
                                >
                                    Register
                                </Button>
                            </Stack>
                        </>
                    )}
                </MHidden>
            </ToolbarStyle>
        </RootStyle>
    );
}
